import React, { useState, useEffect } from 'react';
import Layout from '../layouts/index';
import { graphql } from 'gatsby';
import translationsEn from '../locales/en.json';
import translationsEs from '../locales/es.json';

const ContentPage = ({ data }) => {
  const contentPage = data.datoCmsContentpage;

  const [locale, setLocale] = useState('es');

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedLocale = localStorage.getItem('locale') || 'es';
      setLocale(storedLocale);
    }

    const handleLanguageChange = (event) => {
      setLocale(event.detail);
    };

    if (typeof window !== "undefined") {
      window.addEventListener('languageChanged', handleLanguageChange);
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener('languageChanged', handleLanguageChange);
      }
    };
  }, []);
  
  const translations = locale === 'es' ? translationsEs : translationsEn;

  return (
    <Layout
      site={data.site}
      seo={{ ...data.site.globalSeo, ...data.site.faviconMetaTags }}
      lang={locale}
      title={contentPage.name + " - OS DO CASAL"}
      description={contentPage.name}
    >
      <div className="Content-Page" key={contentPage.id}>
        <div className="Content-Page__details">
          <h1 className="Content-Page__name">{contentPage.name}</h1>
          <h2 className="Content-Page__content">{contentPage.content}</h2>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($contentPageId: String, $locale: String) {
    datoCmsContentpage(id: { eq: $contentPageId }, locale: { eq: $locale }) {
      id
      seourl
      name
      locale
      content
    }
    site: datoCmsSite {
      faviconMetaTags {
        tags
      }
      globalSeo {
        siteName
      }
    }
  }
`;

export default ContentPage;